<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
      >
        <!--        want a back button here-->
        <b-card>
          <template #header>
            <div class="d-flex align-items-center">
              <!-- Back Button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <!-- Program Title -->
              <h3 class="mb-0 ml-1">
                {{ program.title }}
              </h3>
            </div>
          </template>
          <b-table-simple
            bordered
            responsive
          >
            <b-tr>
              <b-td class="font-weight-bold">
                Program Type
              </b-td>
              <b-td>{{ program.type }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">
                Program Capacity
              </b-td>
              <b-td>{{ program.capacity }}</b-td>
            </b-tr>
            <b-tr v-if="program.ideal_lifecycle_stage">
              <b-td class="font-weight-bold">
                Ideal Lifecycle Stage
              </b-td>
              <b-td>{{ program.ideal_lifecycle_stage }}</b-td>
            </b-tr>
            <b-tr v-if="program.industry">
              <b-td class="font-weight-bold">
                Industry
              </b-td>
              <b-td>{{ program.industry }}</b-td>
            </b-tr>
            <b-tr v-if="program.fund_raising_assistance">
              <b-td class="font-weight-bold">
                Fund Raising Assistance
              </b-td>
              <b-td>{{ fundRaisingAssistanceLabel[program.fundRaisingAssistance] }}</b-td>
            </b-tr>
            <b-tr v-if="program.inhouse_funds">
              <b-td class="font-weight-bold">
                Inhouse Funds
              </b-td>
              <b-td>{{ program.inhouse_funds||"NA" }}</b-td>
            </b-tr>
          </b-table-simple>
          <b-table-simple
            bordered
            responsive
          >
            <b-tr>
              <b-td
                class="font-weight-bold"
                colspan="2"
              >
                Criteria
              </b-td>
              <b-td
                class="font-weight-bold"
              >
                Description
              </b-td>

            </b-tr>
            <b-tr>
              <b-td
                v-if="!program.programs_shortlistingcriteriontables || !program.programs_shortlistingcriteriontables.length"
                colspan="3"
              >
                <em>Nothing to show.</em>
              </b-td>
            </b-tr>
            <b-tr
              v-for="(c, i) in program.programs_shortlistingcriteriontables"
              :key="i"
            >
              <b-td style="width: 60px">
                {{ i + 1 }}
              </b-td>
              <b-td>{{ c.criteria }}</b-td>
              <b-td>{{ c.description || '-' }}</b-td>
            </b-tr>
          </b-table-simple>
          <b-table-simple
            bordered
            responsive
          >
            <b-tr>
              <b-td
                class="font-weight-bold"
                colspan="2"
              >
                Deliverable
              </b-td>
              <b-td
                class="font-weight-bold"
              >
                Description
              </b-td>
            </b-tr>
            <b-tr>
              <b-td
                v-if="!program.programs_deliverablelisttables || !program.programs_deliverablelisttables.length"
                colspan="3"
              >
                <em>Nothing to show.</em>
              </b-td>
            </b-tr>
            <b-tr
              v-for="(d, i) in program.programs_deliverablelisttables"
              :key="i"
            >
              <b-td style="width: 60px">
                {{ i + 1 }}
              </b-td>
              <b-td>{{ d.deliverable }}</b-td>
              <b-td>{{ d.description || '-' }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>

</template>

<script>
import {
  BButton,
  BCard, BCol, BRow, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BButton,

    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTr,
    BTd,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      program: {
        programs_shortlistingcriteriontables: [],
        programs_deliverablelisttables: [],
      },
      required,
      email,
      fundRaisingAssistanceLabel: {
        demoDay: 'Demo Day',
        personalPitching: 'Personal Pitching',
        Liasoning: 'Liasoning',
      },
    }
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
          programs_basicinfo_by_pk(id: "${this.$route.params.id}") {
            type
            title
            capacity
            description
            ideal_lifecycle_stage
            industry
            programs_shortlistingcriteriontables {
              criteria
              description
            }
            programs_deliverablelisttables {
              description
              deliverable
            }
            inhouse_funds
            inhouse_ticket_size
            ticket_size_of_fund_raised
            fund_raising_assistance
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
